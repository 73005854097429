<template>
  <div class="tickets">
    <div class="row mt-3 mb-3">
      <h1 class="col-sm-12 text-right">Tickets {{ area.nombre }}</h1>
    </div>

    <div class="row mb-3">
      <div class="col-sm-12 text-right">
        <button @click="obtener_tickets" class="btn btn-secondary">Recargar</button>
      </div>
    </div>
    
    <div class="contenedor_etapas">
      <div class="etapas">
        <div class="fila">
          <div v-for="etapa in area.etapas" :key="etapa.id" class="columna">
            <div class="etapa">{{ etapa.etapa }}</div>

            <div class="cards">
              <div v-for="ticket in obtener_tickets_etapa(etapa.posicion)" :key="ticket.id" :class="estatus_ticket(ticket, etapa)">
                <div class="card-body">
                  <h5 class="card-title">{{ ticket.id+' - '+ticket.ticket }}</h5>
                  <h6 class="card-subtitle mb-2 text-muted text-right">{{ ticket.etapas[0].etapa.nombre }}</h6>
                  <ul>
                    <li :title="$moment(ticket.created_at).format(datetime_format)"><div class="subtitle">Creación:</div class="subtitle"> {{ $moment(ticket.created_at).format(datetime_format) }}</li>
                    <li :title="ticket.nombre"><div class="subtitle">Creado por:</div class="subtitle"> {{ ticket.nombre }}</li>
                    <li :title="ticket.usuario_asignado_nombre ? ticket.usuario_asignado_nombre : 'N/A'"><div class="subtitle">Asingación:</div class="subtitle"> {{ ticket.usuario_asignado_nombre ? ticket.usuario_asignado_nombre : 'N/A' }}</li>
                    <li :title="ticket.asignacion_at ? $moment(ticket.asignacion_at).format(datetime_format) : 'N/A'"><div class="subtitle">Fecha asignación:</div class="subtitle"> {{ ticket.asignacion_at ? $moment(ticket.asignacion_at).format('dd DD MMM YYYY') : 'N/A' }}</li>
                    <li v-if="etapa.tipo == 0"><div class="subtitle">Tiempo máximo en etapa:</div class="subtitle"> {{ $helper.diff_human(etapa.tiempo_vida) }}</li>
                    <li v-if="etapa.tipo == 0"><div class="subtitle">Tiempo en etapa:</div class="subtitle"> {{ $helper.diff_human(ult_etapa_activa(ticket).created_at, 'now', true) }}</li>
                    <li v-if="etapa.tipo == 0"><div class="subtitle">Tiempo retraso:</div class="subtitle"> {{ tiempo_sin_margen(ticket) }}</li>
                  </ul>
                  <div class="text-right">
                    <a href="#" class="btn btn-secondary btn-sm" @click="detalle_ticket(ticket)">Detalles</a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    
    <Detalle v-if="modal_detalle" :ticket_id="ticket.id" :area="area" @update="obtener_tickets" @close="cerrar_modal_detalle" />
  </div>
</template>

<script type="text/javascript">
  import Detalle from './Ticket/Detalle'

  import apiMiArea from '@/apps/tickets/api/miarea'
  import apiTicket from '@/apps/tickets/api/tickets'

  export default {
    components: {
      Detalle
    }
    ,data() {
      return {
        etapas: []
        ,catalogo: {
          optiones: []
        }
        ,area: {
          etapas: []
          ,requerimientos: []
          ,usuarios: []
        }
        ,tickets: []
        ,modal_detalle: false
        ,ticket: {
          id: null
          ,tipo: null
          ,detalle: null
        }
        ,cargas: 0
        ,datetime_format: 'DD MMM YYYY HH:mm'
      }
    }
    ,mounted() {
      this.obtener_dependencias();
    }
    ,methods: {
      async obtener_dependencias() {
        try {
          this.area = (await apiMiArea.obtener_area()).data;

          this.obtener_tickets();
        }catch(e) {
          this.$log.error('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async obtener_tickets() {
        try {
          this.tickets = (await apiTicket.obtener_tickets()).data;
        }catch(e) {
          this.$log.error('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,obtener_tickets_etapa(etapa) {
        let tickets = [];

        for(let i=0; i<this.tickets.length; i++) {
          let ticket_etapas = this.ult_etapa_activa(this.tickets[i]);
          if (ticket_etapas.posicion == parseInt(etapa))
            tickets.push(this.tickets[i]);
        }

        return tickets;
      }
      ,obtener_requerimientos(id) {
        let requerimiento = null;

        this.area.requerimientos.forEach(req => {
          if (req.id == id)
          requerimiento = req;
        })

        return requerimiento.nombre;
      }
      ,detalle_ticket(ticket) {
        this.ticket = ticket;
        this.modal_detalle = true;
      }
      ,cerrar_modal_detalle() {
        this.ticket = {
          id: null
          ,tipo: null
          ,detalle: null
        }
        this.modal_detalle = false;
      }
      ,estatus_ticket(ticket, etapa) {
        this.$log.info('etapa', etapa);

        let etapa_actual = this.ult_etapa_activa(ticket);

        if (etapa_actual.estatus == 2)
          return 'card';

        if (etapa_actual.estatus == 3)
          return 'card text-white bg-secondary';

        let tiempo_etapa = etapa.tiempo_vida;
        let porcentaje = etapa.porcentaje_retraso;
        this.$log.info('tiempo_etapa', tiempo_etapa);
        this.$log.info('porcentaje', porcentaje);
        let segundos_porcentaje = (tiempo_etapa * porcentaje) / 100;
        this.$log.info('segundos_porcentaje', segundos_porcentaje);

        let hoy = this.$moment();
        let inicio = this.$moment(etapa_actual.created_at);

        let segundos_diferencia = hoy.unix() - inicio.unix();
        this.$log.info(ticket.id+' segundos_diferencia', segundos_diferencia);

        if (segundos_diferencia < segundos_porcentaje)
          return 'card text-white bg-success'

        if (segundos_diferencia <= tiempo_etapa)
          return 'card text-white bg-warning';

        return 'card text-white bg-danger';
      }
      ,tiempo_sin_margen(ticket) {
        let etapa = this.ult_etapa_activa(ticket);
        let tiempo_etapa = etapa.etapa.tiempo_vida;
        let porcentaje = etapa.etapa.porcentaje_retraso;
        let segundos_porcentaje = (tiempo_etapa * porcentaje) / 100;

        let hoy = this.$moment();
        let inicio = this.$moment(etapa.created_at);

        inicio.add(segundos_porcentaje, 'seconds');

        let diferencia_humano =  this.$helper.diff_human(inicio.format('YYYY-MM-DD HH:mm:ss'), 'now', true);

        return diferencia_humano != '' ? diferencia_humano : 'Aún en tiempo';
      }
      ,ult_etapa_activa(ticket) {
        let etapas = [];

        let etapas_ticket = this.$helper.clone(ticket.etapas);
        etapas_ticket.sort((a,b) => {
          return a.posicion < b.posicion ? 1 : -1;
        })
        // this.$log.info('etapas_ticket', etapas_ticket);

        etapas_ticket.forEach(etapa => {
          if (etapa.estatus == 1)
            etapas.push(etapa)
        });

        if (etapas.length == 0)
          etapas.push(etapas_ticket[0]);

        etapas.sort((a,b) => {
          return a.posicion < b.posicion ? -1 : 1;
        })

        // this.$log.info('etapas: ',etapas);

        return etapas[0];
      }
    }
    ,watch: {
      cargas(val) {
        if (val >= 2)
          this.obtener_tickets()
      }
    }
  }
</script>

<style lang="scss">
  .constelacion {
    height: 100vh !important;
    main {
      width: 100%;
      height: 100%;

      overflow:hidden;

      .content {
        width: 100%;
        height: calc(100% - 72px);

        .tickets {
          width: 100%;
          height: 100%;

          .contenedor_etapas {
            width: 100%;
            height: calc(100% - 100px);

            .etapas {
              width: 100%;
              height: 100%;
              overflow: hidden;
              overflow-x: auto;

              .fila {
                height: 100%;
                margin-right: 10px;
                display: inline-flex;

                .columna {
                  width: 350px;
                  height: 100%;
                  background-color: #eeeeee;
                  margin-right: 5px;
                  display: inline-block;

                  .etapa {
                    background-color: #4c4c4c;
                    font-size: 1.2em;
                    color: #fff;
                    padding: 10px 20px;
                  }

                  .cards {
                    height: calc(100% - 60px);;
                    padding: 10px;
                    overflow: hidden;
                    overflow-y: auto;

                    .card {
                      margin-bottom: 10px;

                      ul {
                        font-size: 0.8em;
                        list-style-type: none;
                        padding: 0px;
                        margin: 0px;
                        margin-bottom: 20px;

                        li {
                          text-overflow: ellipsis;
                          margin-bottom: 5px;
                          overflow: hidden;

                          .subtitle {
                            background-color: #cccccc;
                            width: 120px;
                            padding: 3px 5px;
                            display: inline-block;
                          }
                        }

                        li:last-child {
                          border: 0px;
                        }
                      }
                    }

                    .text-white {
                      .card-title {
                        color: #fff !important;
                      }
                    }

                    .card:last-child {
                      margin: 0px;
                    }

                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>